import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Section from "./section";
import styles from "./subHero.module.scss";
import Heading from "../components/heading";
import LeadCopy from "../components/leadCopy";
import globals from "../globals";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
import { gsap } from "gsap";
//import "../plugins/debug.addIndicators";

const SubHero = (props) => {

    const containerRef = useRef(null);
    const headingRef = useRef(null);
    const copyRef = useRef(null);

    useEffect(() => {
        let controller = new ScrollMagic.Controller();

        if (!props.isMobile) {
            const tl = gsap.timeline();
            tl.from(headingRef.current, {y: '15vh', opacity: 0, duration: 0.5});
            tl.from(copyRef.current, {y: '20vh', opacity: 0, duration: 0.5}, 0);

            new ScrollMagic.Scene({
                triggerElement: containerRef.current,
                triggerHook: 'onEnter',
                duration: 400,
                offset: 250
            })
                .setTween(tl)
                //.addIndicators({name: 'subhero'})
                .addTo(controller);
        }
    }, [props.isMobile])

    return (
        <Section className={styles.subHero} id="subHero">
            <div ref={containerRef} className="row text-center">
                <div className="col-12 col-lg-6 offset-lg-3">
                    <div ref={headingRef}>
                        <Heading type="h2" className="mb-4">{globals.noWidows('Making travel easier for your guests')}</Heading>
                    </div>
                    <div ref={copyRef}>
                        <LeadCopy>
                            {globals.noWidows(`Guests can access your staff’s local knowledge and recommendations, hostel information, events and more on your Hostelify. Helping your guests to fully experience travel in a new location.`)}
                        </LeadCopy>
                    </div>
                </div>
            </div>
        </Section>
    );

};

SubHero.propTypes = {
    isMobile: PropTypes.bool
}

export default SubHero;