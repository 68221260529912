import React from "react";
import PropTypes from "prop-types";
import styles from "./panel.module.scss";
import Heading from "./heading";
import globals from "../globals";

const Panel = (props) => (

    <div className={styles.panel}>
        <div className={`${styles.line} ${props.align === 'left' ? styles.left : styles.right}`} />
        <br />
        <Heading type="h4" className="mb-2 mb-lg-3">{props.title}</Heading>
        <p className="m-0" dangerouslySetInnerHTML={{__html: globals.noWidows(props.description)}} />
    </div>

);

Panel.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'right'])
};

export default Panel;