import React from "react"
import { useMedia } from "react-use";
import Layout from "../layout/layout"
import SEO from "../components/seo"
import Hero from "../layout/hero";
import SubHero from "../layout/subHero";
import Benefit from "../layout/benefit";
import Features from "../layout/features";
import EarlyAdopters from "../layout/earlyAdopters";
import Bottom from "../layout/bottom";
import Plane from "../components/plane";
import benefit1 from "../assets/images/benefit-1.svg";
import benefit2 from "../assets/images/benefit-2.svg";
import benefit3 from "../assets/images/benefit-3.svg";
import benefit4 from "../assets/images/benefit-4.svg";
import benefit5 from "../assets/images/benefit-5.svg";
import Pricing from "../layout/pricing";
import Screens from "../layout/screens";
import BenefitHeading from "../layout/benefitHeading";

const IndexPage = () => {

    const isMobile = useMedia('(max-width: 992px)');

    return (
        <Layout>
            <SEO title="Home" />
            <Hero />
            <SubHero isMobile={isMobile} />
            <Screens isMobile={isMobile} />
            <BenefitHeading />
            <Benefit
                image={benefit1}
                title="Help your guests before they arrive"
                description="Direct your guests to your Hostelify with your custom Hostelify URL prior to arrival so guests can start planning their experience based on your local expertise from the moment they book not the moment they arrive."
                align="right"
                id="benefit-1"
            />
            {!isMobile ? <Plane /> : ''}
            <Benefit
                image={benefit2}
                title="Save your staff and guests time"
                description="How many of your guests get the same introduction to your city? Instead throw all your staff’s local knowledge and recommendations, hostel info and more straight into Hostelify for easy access by all guests on demand."
                align="left"
            />
            <Benefit
                image={benefit3}
                title="Add to your guests experience"
                description="Guest can use our interactive map or browse through Hostelify to find your staff’s picks on the best things to see, eat, do and enjoy in your city or town to make sure they have the best and most local experience they can."
                align="right"
            />
            <Benefit
                image={benefit4}
                title="Available offline"
                description="Think of Hostelify as your personalised guide for guests to your city and hostel. Guests can take your Hostelify with them while exploring and have access to all of your local suggestion goodness without needing to find WiFi or a travel SIM."
                align="left"
            />
            <Benefit
                image={benefit5}
                title="Improve sociability"
                description="Help backpackers fresh off the overnight bus to make friends by encouraging guests to post their plans on the Hostelify noticeboard to see if others are interested in joining."
                align="right"
            />
            <Pricing isMobile={isMobile} />
            <Features isMobile={isMobile} />
            <EarlyAdopters />
            <Bottom isMobile={isMobile} />
        </Layout>
    );

};

export default IndexPage
