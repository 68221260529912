import React, { useEffect, useRef } from "react";
import Section from "./section";
import topBg from "../assets/images/screens-top.svg";
import bottomBg from "../assets/images/screens-bottom.svg";
import styles from "./screens.module.scss";
import Heading from "../components/heading";
import loadable from '@loadable/component';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { gsap } from "gsap";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
//import "../plugins/debug.addIndicators";
import screenEvents from "../assets/images/iphone-events.png";
import screenHostel from "../assets/images/iphone-hostel.png";
import screenMap from "../assets/images/iphone-map.png";
import screenNoticeboard from "../assets/images/iphone-noticeboard.png";
import globals from "../globals";
import Button from "../components/button";
import {useMedia} from "react-use";

const Screens = (props) => {

    const isMobile = useMedia('(max-width: 992px)');

    useEffect(() => {
        let controller = new ScrollMagic.Controller();
        let tl = gsap.timeline();
        tl.fromTo('.screen-item', {y: '-100px', opacity: 0}, {opacity: 1, y: 0, duration: 2, stagger: 0.5, ease: 'power1.out'}, 0);

        new ScrollMagic.Scene({
            triggerElement: '#screens',
            triggerHook: 'onLeave',
            duration: 1000,
            offset: -400,
        })
            .setTween(tl)
            //.addIndicators({name: 'subhero'})
            .addTo(controller);
    });

    const screens = [
        {title: 'Hostel info', image: screenHostel},
        {title: 'Your recommendations', image: screenMap},
        {title: 'Events', image: screenEvents},
        {title: 'Noticeboard', image: screenNoticeboard},
    ];

    const mobileOptions = {
        items: 1,
        loop: true,
        center: false,
        margin: 15,
        stagePadding: 0
    };

    const Carousel = (options) => {
        if (typeof window !==  'undefined') {
            const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
            return <ReactOwlCarousel {...options}>
                {screens.map((item, key) => {
                    return (
                        <div key={key} className={styles.screenMobile}>
                            <p className="m-0 mb-2">{item.title}</p>
                            <div className={styles.image}>
                                <img src={item.image} alt={item.title} />
                            </div>
                        </div>
                    );
                })}
            </ReactOwlCarousel>;
        }

        return null;
    };

    return (
        <Section className={styles.section} innerClassName={styles.inner} id="screens">
            <img src={topBg} alt="" width="100%" />
            <div className={styles.screens}>
                <div className="row">
                    <div className="col-12 col-lg-6 offset-lg-3 text-center mb-3 mb-lg-5" id="nav-demo">
                        <Heading type="h3" className={`${styles.heading} mb-0`}>What it looks like</Heading>
                        <p dangerouslySetInnerHTML={{__html: globals.noWidows('Check out a few screenshots of our demo app below. When you sign up with Hostelify, your guests will be able to access your hostel using a custom sub-domain such as abc.hostelify.app or by using a QR code at reception, easy peasy!')}} className="d-none d-lg-inline-block" />
                    </div>
                    <div className="col-12 col-xl-10 offset-xl-1 d-none d-lg-flex">
                        {screens.map((item, key) => {
                            return (
                                <div key={key} className={`${styles.screen} screen-item`}>
                                    <p className="m-0 mb-2">{item.title}</p>
                                    <div className={styles.image}>
                                        <img src={item.image} alt={item.title} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="col-12 d-lg-none">
                        <div className="row position-relative text-center">
                            <div className={styles.mobileOverlay} />
                            <div className={styles.carousel}>{Carousel(mobileOptions)}</div>
                        </div>
                    </div>
                </div>
                <div className={styles.demo}>
                    <Button handleClick={() => window.open('https://proto.hostelify.app', "_blank")}>View live demo</Button>
                    {!isMobile ? <p className="mt-1">(works best on mobile)</p> : ''}
                </div>
            </div>
            <img src={bottomBg} alt="" width="100%" />
        </Section>
    );

}

export default Screens;