import React, { useRef, useEffect } from "react";
import { useMedia } from "react-use";
import styles from './hero.module.scss';
import Heading from "../components/heading";
import Button from "../components/button";
import Section from "./section";
import LeadCopy from "../components/leadCopy";
import globals from "../globals";
import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
//import "../plugins/debug.addIndicators";
import mobileBg from "../assets/images/bg-hero-mobile.svg";
import ArrowDown from "../components/arrowDown";

gsap.registerPlugin(ScrollToPlugin);

const Hero = (props) => {

    const isMobile = useMedia('(max-width: 992px)');
    const text = useRef(null);
    const textMobile = useRef(null);
    const heading = useRef(null);
    const headingMobile = useRef(null);
    const button = useRef(null);
    const buttonMobile = useRef(null);

    useEffect(() => {
        if (!isMobile) {
            let controller = new ScrollMagic.Controller();
            let tl = gsap.timeline();

            tl.to([heading.current, headingMobile.current], {y: '-25vh', opacity: 0, duration: 0.5});
            tl.to([text.current, textMobile.current], {y: '-20vh', opacity: 0, duration: 0.5}, 0);
            tl.to([button.current, buttonMobile.current], {y: '-22vh', opacity: 0, duration: 0.5}, 0);

            new ScrollMagic.Scene({
                triggerElement: '#hero',
                triggerHook: 'onLeave',
                duration: 800,
            })
                .setTween(tl)
                //.addIndicators({name: 'hero'})
                .addTo(controller);
        }
    });

    const handleClickExplore = () => {
        if (typeof window !== 'undefined') {
            if (isMobile) {
                const vh = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
                gsap.to(window, {duration: 1, scrollTo: vh});
            } else {
                gsap.to(window, {duration: 1, scrollTo: {y: "#subHero", offsetY: 150}});
            }
        }
    };

    const Title = `Simplifying <span class="${styles.br}"><br /></span>the <span class="${styles.brLg}"><br /></span>hostel <span class="${styles.br}"><br /></span>experience`;

    const Content = (isDesktop) => (
        <Section className={styles.hero} id="hero">
            <div className="row h-100">
                <div className="col-12 col-lg-7 offset-lg-1 d-flex align-items-lg-center h-100">
                    <div className={styles.heroWrap}>
                        <div ref={isDesktop ? heading : headingMobile}>
                            <Heading type="h1" ignoreWidows={true}>
                                {Title}
                            </Heading>
                        </div>
                        <div className="row no-gutters">
                            <div className="col-12 col-lg-8">
                                <div className={styles.leadCopy} ref={isDesktop ? text : textMobile}>
                                    <LeadCopy className="mb-4 mb-lg-5">{globals.noWidows('Your hostel\'s very own web app to ensure your guests can experience the awesomeness that your hostel and city have to offer.')}</LeadCopy>
                                </div>
                            </div>
                        </div>
                        <div ref={isDesktop ? button : buttonMobile} className={styles.btn}>
                            <Button handleClick={() => window.open('https://proto.hostelify.app', "_blank")}>View live demo</Button>
                            {!isMobile ? <p className="mt-1">(works best on mobile)</p> : ''}
                        </div>
                    </div>
                </div>
            </div>
        </Section>
    );

    return (
        <>
            <div className="d-none d-lg-block">
                {Content(true)}
            </div>
            <div className="d-block d-lg-none">
                <div className={styles.background}>
                    <img src={mobileBg} alt="" className={styles.bgImage} />
                    {Content(false)}
                </div>
            </div>
        </>
    );

}

export default Hero;