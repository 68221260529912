import React from "react";
import globals from "../globals";
import styles from "./leadCopy.module.scss";

const LeadCopy = (props) => (

    <p className={`${styles.leadCopy} ${props.className}`} dangerouslySetInnerHTML={{__html: globals.noWidows(props.children)}} />

);

export default LeadCopy;