import React from "react";
import PropTypes from "prop-types";
import styles from "./feature.module.scss";
import icon from "../../src/assets/images/icon-feature.svg";

const Feature = (props) => {

    return (
        <div className={`${styles.wrapper} feature-item`}>
            <div className={styles.feature}>
                <div className={`${styles.iconWrapper} text-center`}>
                    <img src={icon} alt={props.title} className={styles.icon} />
                </div>
                <div className={styles.inner}>
                    <h6>{props.title}</h6>
                    <p className="mb-0">{props.description}</p>
                </div>
            </div>
        </div>
    );

};

Feature.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Feature;
