import React, { useRef } from "react";
import { useIntersection, useMedia } from "react-use";
import { gsap } from "gsap";
import PropTypes from "prop-types";
import Section from "./section";
import styles from "./benefit.module.scss";
import Panel from "../components/panel";
import globals from "../globals";

const Benefit = (props) => {

    const containerRef = useRef(null);
    const imageRef = useRef(null);
    const contentRef = useRef(null);
    const isMobile = useMedia('(max-width: 992px)');

    const intersection = useIntersection(containerRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.75
    });

    const fadeIn = () => {
        const tl = gsap.timeline();
        if (isMobile) {
            tl.to(imageRef.current, {y: 0, opacity: 1, duration: 0.3});
            tl.to(contentRef.current, {y: 0, opacity: 1, duration: 0.3}, '-=0.15');
        } else {
            tl.to(contentRef.current, {y: 0, opacity: 1, duration: 0.5});
            tl.to(imageRef.current, {y: 0, opacity: 1, duration: 0.5}, '-=0.25');
        }
    };

    if (intersection && intersection.intersectionRatio > 0.75) {
        fadeIn();
    }

    const Image = (
        <div ref={imageRef} className={styles.backgroundWrap}>
            <img src={props.image} alt={props.title} className={styles.background} />
        </div>
    );

    const Content = (
        <div ref={contentRef} className={styles.contentWrap}>
            <Panel title={isMobile ? globals.noWidows(props.title) : props.title} description={props.description} align={props.align} />
        </div>
    );

    return (
        <Section className={styles.benefits} id={props.id ? props.id : ''}>
            <div ref={containerRef} className="row">
                {isMobile ?
                    <React.Fragment>
                        <div className="col-12 col-lg-5 offset-lg-1 d-flex justify-content-center align-items-center">
                            {Image}
                        </div>
                        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
                            {Content}
                        </div>
                    </React.Fragment>
                :
                    <React.Fragment>
                        <div className="col-12 col-lg-5 offset-lg-1 d-flex justify-content-center align-items-center">
                            {props.align === 'left' ? Content : Image}
                        </div>
                        <div className="col-12 col-lg-5 d-flex justify-content-center align-items-center">
                            {props.align === 'left' ? Image : Content}
                        </div>
                    </React.Fragment>
                }

            </div>
        </Section>
    );

};

Benefit.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    align: PropTypes.oneOf(['left', 'right']).isRequired
};

export default Benefit;