import React from "react";
import { useMedia } from "react-use";
import PropTypes from "prop-types";
import styles from "./featureBtn.module.scss";

const FeatureBtn = React.forwardRef((props, ref) => {

    const isMobile = useMedia('(max-width: 992px)');

    const handleMouseEnter = (e) => {
        if (!isMobile) {
            e.stopPropagation();
            props.handleMouseEnter(ref);
        }
    };

    const handleMouseLeave = (e) => {
        if (!isMobile) {
            e.stopPropagation();
            props.handleMouseLeave();
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        props.handleMouseEnter(ref);
        props.handleClick(e);
    };

    return (
        <div
            ref={ref}
            className={`feature-btn feature-btn-${props.type} ${styles.btn} ${props.active ? styles.active : ''}`}
            onClick={handleClick.bind(this)}
            onMouseEnter={handleMouseEnter.bind(this)}
            onMouseLeave={handleMouseLeave.bind(this)}
        >
            <span>{props.title}</span>
        </div>
    );

});

FeatureBtn.propTypes = {
    title: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    type: PropTypes.string.isRequired,
    handleMouseEnter: PropTypes.func.isRequired,
    handleMouseLeave: PropTypes.func.isRequired
};

export default FeatureBtn;