import React, {useEffect, useRef} from "react";
import Heading from "../components/heading";
import styles from "./benefitHeading.module.scss";
import { useIntersection, useMedia } from "react-use";
import { gsap } from "gsap";
import ScrollMagic from "../plugins/ScrollMagic";
import globals from "../globals";

const BenefitHeading = (props) => {

    const containerRef = useRef(null);
    const isMobile = useMedia('(max-width: 992px)');

    useEffect(() => {
        let controller = new ScrollMagic.Controller();

        if (!isMobile) {
            const tl = gsap.timeline();
            tl.from(containerRef.current, {y: '-50px', opacity: 0, duration: 0.5});

            new ScrollMagic.Scene({
                triggerElement: '#benefit-heading',
                triggerHook: 'onEnter',
                duration: 500,
                offset: 250
            })
                .setTween(tl)
                //.addIndicators({name: 'subhero'})
                .addTo(controller);
        }
    }, [])

    return (
        <div ref={containerRef} className={styles.container} id="benefit-heading">
            <Heading type="h3">{globals.noWidows('A few of the benefits')}</Heading>
        </div>
    );

};

export default BenefitHeading;