import React from "react";
import PropTypes from "prop-types";
import styles from "./plan.module.scss";
import icon from "../../src/assets/images/icon-plan.svg";

const Plan = (props) => {

    return (
        <div className={`${styles.wrapper} plan-item`}>
            <div className={styles.feature}>
                <div className={`${styles.iconWrapper} text-center`}>
                    <img src={icon} alt={props.title} className={styles.icon} />
                </div>
                <div className={styles.inner}>
                    <h5 className="mb-0">{props.title}</h5>
                    <p className={styles.price}>{props.price}</p>
                    <p className="mb-0">{props.description}</p>
                </div>
            </div>
        </div>
    );

};

Plan.propTypes = {
    title: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default Plan;
