import React, { useRef } from "react";
import { useIntersection } from "react-use";
import Section from "./section";
import Heading from "../components/heading";
import styles from "./earlyAdopters.module.scss";
import Obfuscate from "react-obfuscate";
import { gsap } from "gsap";

const EarlyAdopters = () => {

    const containerRef = useRef(null);
    const headingRef = useRef(null);

    const intersection = useIntersection(containerRef, {
        root: null,
        rootMargin: '0px',
        threshold: 0.75
    });

    const fadeIn = () => {
        const tl = gsap.timeline();
        const pTags = document.querySelectorAll('.early-adopters-content p');
        tl.to(headingRef.current, {y: 0, opacity: 1, duration: 0.3});
        tl.to(pTags, {y: 0, opacity: 1, duration: 0.5, stagger: 0.1}, '-=0.4');
    };

    if (intersection && intersection.intersectionRatio > 0.75) {
        fadeIn();
    }

    return (
        <Section className={styles.container}>
            <div ref={containerRef} className="row mb-lg-5">
                <div className="col-12 col-lg-8 offset-lg-2 text-center">
                    <div ref={headingRef} className={styles.animation}>
                        <Heading type="h3" className="mb-4">Early adopters</Heading>
                    </div>
                    <div className={`${styles.textWrapper} early-adopters-content`}>
                        <p className={styles.animation}>Keen to be one of the first hostels to use Hostelify? We are looking for a very small number of hostels to get involved and help us make this tool as helpful as possible for you, your guests and the hostel industry. We want hostels that take their guests experience seriously and want to improve it further. </p>
                        <p className={styles.animation}>If you're interested in being one of our early adopters and setting up Hostelify in your hostel, you will be able to help us fine tune our product with your direct feedback improving or adding to its features meaning more value for you and your guests.</p>
                        <p className={styles.animation}>Sound good? We hope so! If you're interested or have any questions send us an email at <Obfuscate email="hello@hostelify.app" /> and we can discuss it further.</p>
                    </div>
                </div>
            </div>
        </Section>
    );

};

export default EarlyAdopters;