import React from "react";
import Section from "./section";
import topBg from "../assets/images/features-top.svg";
import bottomBg from "../assets/images/features-bottom.svg";
import styles from "./features.module.scss";
import Heading from "../components/heading";
import Feature from "../components/feature";
import loadable from '@loadable/component';
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import PlaneWhite from "../components/planeWhite";
import { gsap } from "gsap";
import FeatureBtn from "../components/featureBtn";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
//import "../plugins/debug.addIndicators";

const EXPLORING = 'exploring';
const LIVING = 'living';
const MANAGEMENT = 'management';

const features = {
    [EXPLORING]: [
        {title: 'Interactive Map', description: 'Guests can view all of your local recommendations on one nice, filterable map. All information also available in list style format also.'},
        {title: 'Available offline', description: 'No WiFi? No problem. Guests do not require data to use Hostelify while travelling around your city.'},
        {title: 'Flexibility', description: 'Add any information you like, from public transport info to local specials or even the best tattoo parlours nearby, if you want your guests to know something, it can go in Hostelify.'},
        {title: 'Recommended routes', description: 'Add routes to help your guests navigate their way through the must see sights in your city with ease. A little easier than hand drawing directions on an oversized paper map.'}
    ],
    [LIVING]: [
        {title: 'Guest noticeboard', description: 'Guests can post their upcoming plans to see if others around your hostel want to join - why not make meeting people easier.'},
        {title: 'Add hostel events', description: 'Featuring upcoming and recurring events happening at your hostel such as walking tours or pub crawls for your guests to easily find and get involved.'},
        {title: 'Important info', description: 'Add WiFi passwords, contact and address details, reception hours, checkout times and any other hostel specific information in one place.'},
        //{title: 'Order a taxi', description: 'Where available guests can order an Uber or similar back to your hostel using Hostelify. Guests may also call local taxi companies through Hostelify for free.'},
        //{title: 'Contacting the hostel', description: 'Guests can call your hostel directly through Hostelify for free. Handy for users who only have access to WiFI or data and need to get in touch with you.'},
        {title: 'Engagement', description: 'Guests have the ability to engage with your recommendations and events by liking and sharing them within Hostelify.'}
    ],
    [MANAGEMENT]: [
        {title: 'Bespoke CMS', description: 'Manage your Hostelify using our bespoke content management system, designed and developed for you to input maximum information with minimal effort.'},
        {title: 'Improving your content', description: 'Easily add information to your suggestions with review ratings, opening hours, addresses and more pulled directly from Google for you at the click of a button.'},
        {title: 'Analytics', description: 'Understand what your guests are doing within your Hostelify, and how they are using it with our built in analytics.'},
        {title: 'Customisable', description: 'Love some features, don’t need others? No worries. Turn on and off what you need and don’t need, Hostelify can be as comprehensive or as lightweight as you need.'}
    ]
};

const positions = {
    [LIVING]: 0,
    [EXPLORING]: 1,
    [MANAGEMENT]: 2
};

class Features extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            feature: LIVING,
            refreshCount: 0
        };

        this.features = [
            LIVING,
            EXPLORING,
            MANAGEMENT
        ];

        this.btnTl = gsap.timeline();

        this.containerRef = React.createRef();
        this.featureBtnBg = React.createRef();
        this.btn1 = React.createRef();
        this.btn2 = React.createRef();
        this.btn3 = React.createRef();
        this.mobileOverlay = React.createRef();
    }

    componentDidMount() {
        if (!this.props.isMobile) {
            this.animateDesktop();
        }
    }

    animateDesktop() {
        gsap.set(`.${this.state.feature}`, {display: 'flex', visibility: 'hidden'});

        const controller = new ScrollMagic.Controller();

        new ScrollMagic.Scene({
            triggerElement: '#features',
            triggerHook: 'onLeave',
            offset: -100
        })
            .setTween(() => {
                gsap.set(`.${this.state.feature}`, {visibility: 'visible'});
                this.handleChangeFeature(this.state.feature);
            })
            //.addIndicators({name: 'features'})
            .addTo(controller);
    }

    handleChangeFeature(type) {
        this.props.isMobile ? this.animateChangeMobile(type) : this.animateChangeDesktop(type);
    }

    handleBtnMouseEnter(type, ref) {
        if (type !== this.state.feature) {
            this.btnTl.clear();
            this.btnTl.set([this.btn1.current, this.btn2.current, this.btn3.current], {color: '#FFFFFF'});
            this.btnTl.to(this.featureBtnBg.current, {x: positions[type] * this.featureBtnBg.current.clientWidth, duration: 1.2, ease: 'elastic.out(0.75, 0.3)'});
            this.btnTl.to(ref.current, {color: '#F06E73', duration: 0.2}, 0);
        }
    }

    handleBtnMouseLeave(type) {
        if (type !== this.state.feature) {
            this.btnTl.clear();
            this.btnTl.set([this.btn1.current, this.btn2.current, this.btn3.current], {color: '#FFFFFF'});
            this.btnTl.to(this.featureBtnBg.current, {x: positions[this.state.feature] * this.featureBtnBg.current.clientWidth, duration: 0.5, ease: 'back.out(1.7)'});
            this.btnTl.to(`.feature-btn-${this.state.feature}`, {color: '#F06E73', duration: 0.2}, 0);
        }
    }

    animateChangeDesktop(type) {
        this.setState({feature: type});
        let tl = gsap.timeline();
        tl.to(`.${this.state.feature} .feature-item`, {
            opacity: 0,
            duration: 0.2,
            ease: "power4.out",
        });
        tl.add(() => {
            this.features.forEach(item => {
                gsap.set(`.${item}`, {display: 'none'});
            });
        });
        tl.set(`.${type}`, {display: 'flex'});
        tl.fromTo(`.${type} .feature-item`, {
            scale: 0,
            opacity: 0
        }, {
            scale: 1,
            opacity: 1,
            duration: 1,
            stagger: 0.1,
            ease: "elastic.out(0.7, 0.4)"
        });
    }

    animateChangeMobile(type) {
        const tl = gsap.timeline();
        tl.set(this.mobileOverlay.current, {display: 'block', scale: 0, opacity: 1, transformOrigin: '50% 50%'});
        tl.to(this.mobileOverlay.current, {scale: 1, duration: 0.4, ease: 'power4.out'});
        tl.add(() => {
            this.setState({feature: type});
        })
        tl.to(this.mobileOverlay.current, {scale: 0, opacity: 0.2, duration: 0.4, ease: 'power4.out'});
    }

    render() {
        const mobileOptions = {
            items: 1,
            loop: false,
            center: false,
            margin: 10,
        };

        const Carousel = (options, feature) => {
            if (typeof window !==  'undefined') {
                const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
                return <ReactOwlCarousel {...options}>
                    {features[feature].map((item, key) => {
                        return <Feature
                            key={key}
                            title={item.title}
                            image={item.image}
                            description={item.description}
                        />;
                    })}
                </ReactOwlCarousel>;
            }

            return null;
        };

        const DesktopItems = (feature) => (
            <div className={`${styles.carouselWrapper} .carousel-wrapper ${feature}`}>
                {features[feature].map((item, key) => {
                    return <Feature
                        key={key}
                        title={item.title}
                        image={item.image}
                        description={item.description}
                    />;
                })}
            </div>
        );

        return (
            <Section className={styles.section} innerClassName={styles.inner} id="features">
                <img src={topBg} alt="" width="100%" />
                <div ref={this.containerRef} className={styles.features}>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center" id="nav-features">
                            <Heading type="h3" className={`${styles.heading} mb-3 mb-lg-5`}>{this.props.isMobile ? 'Features' : 'What about features...'}</Heading>
                        </div>
                        <div className="col-12 col-lg-8 offset-lg-2 text-center justify-content-center">
                            <div className="row no-gutters">
                                <div className="col-12 col-lg-10 offset-lg-1 mb-4 px-2 px-lg-0">
                                    <div className={styles.titles}>
                                        <div ref={this.featureBtnBg} className={styles.titlesBg} />
                                        <FeatureBtn
                                            ref={this.btn1}
                                            title="Hostel living"
                                            handleClick={() => this.handleChangeFeature(LIVING)}
                                            handleMouseEnter={target => this.handleBtnMouseEnter(LIVING, target)}
                                            handleMouseLeave={target => this.handleBtnMouseLeave(LIVING)}
                                            active={this.state.feature === LIVING}
                                            type={LIVING}
                                        />
                                        <FeatureBtn
                                            ref={this.btn2}
                                            title="Exploring"
                                            handleClick={() => this.handleChangeFeature(EXPLORING)}
                                            handleMouseEnter={target => this.handleBtnMouseEnter(EXPLORING, target)}
                                            handleMouseLeave={target => this.handleBtnMouseLeave(EXPLORING)}
                                            active={this.state.feature === EXPLORING}
                                            type={EXPLORING}
                                        />
                                        <FeatureBtn
                                            ref={this.btn3}
                                            title="Management"
                                            handleClick={() => this.handleChangeFeature(MANAGEMENT)}
                                            handleMouseEnter={target => this.handleBtnMouseEnter(MANAGEMENT, target)}
                                            handleMouseLeave={target => this.handleBtnMouseLeave(MANAGEMENT)}
                                            active={this.state.feature === MANAGEMENT}
                                            type={MANAGEMENT}
                                        />
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="row d-none d-lg-flex">
                                        {DesktopItems(EXPLORING)}
                                        {DesktopItems(LIVING)}
                                        {DesktopItems(MANAGEMENT)}
                                    </div>
                                    <div className="row d-lg-none position-relative">
                                        <div ref={this.mobileOverlay} className={styles.mobileOverlay} />
                                        <div className={styles.carousel}>{Carousel(mobileOptions, this.state.feature)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <img src={bottomBg} alt="" width="100%" />
                {!this.props.isMobile ? <PlaneWhite /> : ''}
            </Section>
        );
    }

}

export default Features;