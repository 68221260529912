import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Section from "./section";
import styles from "./pricing.module.scss";
import Heading from "../components/heading";
import globals from "../globals";
import ScrollMagic from "../plugins/ScrollMagic";
import "gsap/src/ScrollToPlugin";
import { gsap } from "gsap";
import Plan from "../components/plan";
import loadable from "@loadable/component";
import Obfuscate from "react-obfuscate";
import Button from "../components/button";
//import "../plugins/debug.addIndicators";

const Pricing = (props) => {

    const containerRef = useRef(null);
    const headingRef = useRef(null);
    const copyRef = useRef(null);
    const priceRef1 = useRef(null);
    const priceRef2 = useRef(null);

    useEffect(() => {
        let controller = new ScrollMagic.Controller();

        if (!props.isMobile) {
            const tl = gsap.timeline();
            tl.from(headingRef.current, {y: '15vh', opacity: 0, duration: 0.5});
            tl.from(copyRef.current, {y: '20vh', opacity: 0, duration: 0.5}, 0);
            tl.fromTo([priceRef1.current, priceRef2.current], {
                scale: 0,
                opacity: 0
            }, {
                scale: 1,
                opacity: 1,
                duration: 1,
                stagger: 0.2,
                ease: "elastic.out(0.7, 0.4)"
            }, 0);

            new ScrollMagic.Scene({
                triggerElement: containerRef.current,
                triggerHook: 'onEnter',
                offset: 150
            })
                .setTween(tl)
                //.addIndicators({name: 'subhero'})
                .addTo(controller);
        }
    }, [props.isMobile]);

    const Plans = () => {
        return (
            <React.Fragment>
                <div ref={priceRef1}>
                    <Plan
                        title="Hostelify"
                        price="Price $39/mth AUD"
                        description="Great for hostels who want their guests to make the most of their location. Control the content yourself and enter your staff recommendations, hostel and local events, includes a noticeboard for staff and guests, useful info and more."
                    />
                </div>
                <div ref={priceRef2}>
                    <Plan
                        title="Hostelify Advanced"
                        price="(coming soon)"
                        description="As well as all of the functionality of Hostelify, Hostelify Advanced includes functionality for guests to book and pay for products directly from your hostel. Products such as tours, rental equipment, late checkouts and much more."
                    />
                </div>
            </React.Fragment>
        );
    };

    const options = {
        items: 1,
        loop: false,
        center: false,
        margin: 15,
        stagePadding: 0
    };

    const Carousel = () => {
        if (typeof window !==  'undefined') {
            const ReactOwlCarousel = loadable(() => import('react-owl-carousel'), { ssr: false });
            return <ReactOwlCarousel {...options}>
                {Plans()}
            </ReactOwlCarousel>;
        }

        return null;
    };

    const handleClick = () => {
        window.location.href = 'mailto:hello@hostelify.app?subject=Getting started with Hostelify&body=Hello!%0d%0a%0d%0aI am keen to get Hostelify setup in my hostel, can you please send me some more information?%0d%0a%0d%0aThanks.'
    };

    return (
        <Section className={styles.subHero} id="pricing">
            <div ref={containerRef} className="row text-center">
                <div className="col-12 col-lg-6 offset-lg-3">
                    <div ref={headingRef}>
                        <Heading type="h3" className="mb-4">{globals.noWidows('Our pricing')}</Heading>
                    </div>
                    <div ref={copyRef}>
                        <p className={styles.leadCopy}>
                            Get Hostelify set up at your hostel for the price of a bed for a night or two, seems like a pretty good deal to us! Have questions? No worries, send us an email at <Obfuscate email="hello@hostelify.app" />.
                        </p>
                    </div>
                </div>
                <div className="col-12 col-lg-8 offset-lg-2 d-none d-lg-flex justify-content-center">
                    {Plans()}
                </div>
                <div className="col-12 d-lg-none">
                    {Carousel()}
                </div>
                <div className="col-12 text-center mt-5">
                    <Button handleClick={handleClick}>Sign me up</Button>
                </div>
            </div>
        </Section>
    );

};

Pricing.propTypes = {
    isMobile: PropTypes.bool
}

export default Pricing;